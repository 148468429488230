
.storage-mode {
  min-height: 600px;
  .storage-item {
    width: 700px;
    padding: 10px 6px;
    margin-top: 10px;
    border: 1px solid #f2f2f2;
    .item-txt {
      font-size: 14px;
    }
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;