.storage-mode {
  min-height: 600px;
}
.storage-mode .storage-item {
  width: 700px;
  padding: 10px 6px;
  margin-top: 10px;
  border: 1px solid #f2f2f2;
}
.storage-mode .storage-item .item-txt {
  font-size: 14px;
}
